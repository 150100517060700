var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "standalone-hosting-form" }, [
    _c(
      "form",
      { ref: "standalone-hosting-form" },
      [
        _vm.domainAvailable
          ? _c(
              "div",
              [
                _c("b-col", [
                  _c(
                    "div",
                    { staticClass: "text-center" },
                    [
                      _c("p", [
                        _vm._v(
                          "Looks like the domain you entered isn't registered."
                        ),
                      ]),
                      _c(
                        "b-button",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            variant: "outline-danger",
                            "aria-label": "Close button",
                          },
                          on: { click: _vm.closeModal },
                        },
                        [_vm._v("\n            Exit\n          ")]
                      ),
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "primary",
                            "aria-label": "Try again button",
                          },
                          on: { click: _vm.tryAgain },
                        },
                        [_vm._v("\n            Try Again\n          ")]
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            )
          : _c("validation-observer", {
              ref: "observer",
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ invalid }) {
                    return [
                      _c(
                        "b-row",
                        { staticClass: "px-4 mt-4" },
                        [
                          _c(
                            "b-col",
                            [
                              _c("label", [_vm._v("Domain")]),
                              _c("validation-provider", {
                                attrs: {
                                  name: "domain",
                                  rules: "required|validDomain",
                                  immediate: "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({
                                        errors,
                                        validate,
                                        validated,
                                      }) {
                                        return [
                                          _c("b-form-input", {
                                            attrs: {
                                              type: "text",
                                              state: validated
                                                ? !errors.length
                                                : null,
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.markDirty(
                                                  "domainName"
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.standaloneHostingRequest
                                                  .domainName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.standaloneHostingRequest,
                                                  "domainName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "standaloneHostingRequest.domainName",
                                            },
                                          }),
                                          _c(
                                            "b-form-invalid-feedback",
                                            {
                                              attrs: {
                                                id: "domain-error-feedback",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                '\n              Enter your domain in all lowercase without "www", "https", or special characters. (e.g., example.com).\n            '
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "px-4" },
                        [
                          _c("b-col", [
                            _c("span", { staticClass: "text--error" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.errorMessage) +
                                  "\n          "
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "px-4 py-3" },
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c("div", [
                                _vm._v(
                                  "Want to talk to a person about starting?"
                                ),
                              ]),
                              _c(
                                "b-checkbox",
                                {
                                  staticClass: "text--teal",
                                  nativeOn: {
                                    click: function ($event) {
                                      _vm.inputDirty.requestCall = true
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.standaloneHostingRequest.requestCall,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.standaloneHostingRequest,
                                        "requestCall",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "standaloneHostingRequest.requestCall",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            Request a call\n          "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("b-col"),
                          _c(
                            "b-col",
                            { attrs: { cols: "auto" } },
                            [
                              _vm.cancellable
                                ? _c(
                                    "b-button",
                                    {
                                      staticClass: "mr-2",
                                      attrs: {
                                        variant: "outline-danger",
                                        "aria-label": "Cancel button",
                                        disabled: _vm.loading,
                                      },
                                      on: { click: _vm.closeModal },
                                    },
                                    [_vm._v("\n            Cancel\n          ")]
                                  )
                                : _vm._e(),
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    variant: "primary",
                                    "aria-label": _vm.submitText + "button",
                                    disabled: _vm.loading || invalid,
                                  },
                                  on: { click: _vm.submitRequest },
                                },
                                [
                                  _c(
                                    "b-overlay",
                                    {
                                      attrs: {
                                        show: _vm.loading,
                                        rounded: "sm",
                                        opacity: 0,
                                        variant: "black",
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.submitText)),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }